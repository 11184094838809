@use "../config/" as *;
.photo-listing {
    @extend %c-blue-3-bg-10;
    .waf-listing {
        padding: calc(var(--one-n-half-space) + 4.1rem + var(--full-space)) var(--full-space);
        .article-list {
            flex-direction: column;
        }
        .article-item {
            border-radius: 1.2rem;
            @extend %w-100;
        }
    }
    .article {
        &-item {
            position: relative;
            cursor: pointer;
            @extend %m-b-full;
        }
        &-thumbnail {
            @extend %c-blue-1-bg-10;
            border: 0;
        }
        &-meta {
            @extend %d-none;
        }
        &-action {
            .btn-text {
                @extend %font-zero;
            }
            .btn-download {
                // @extend %d-none;
                @extend %flex-c-c;
                @include position(var(--half-space), var(--half-space));
                &::before {
                    @extend %c-white-10;
                    @include icon('\e809', 20);
                }
                &:hover {
                    &::before {
                        @extend %c-orange-10;
                    }
                }
            }
            .btn-more {
                @extend %d-none;
            }
        }
    }
    .btn-more {
        @extend %w-100;
        .btn-text {
            @extend %font-14-pb;
            @extend %c-orange-10;
        }
    }
    .filter-section {
        width: calc(100% - var(--two-space));
        @include position(var(--one-n-half-space), null, null, var(--full-space));
        input {
            @include border-radius(50vmax);
            // @extend %c-white-bg-6;
            @extend %c-blue-2-bg-10;
        }
        .filter-search {
            @extend %w-100;
            .btn-close {
                @extend %p-r-half;
                .btn-text {
                    &::after {
                        @extend %c-blue-light-10;
                    }
                }
            }
        }
        .filter-body,
        .filter-toggle,
        .filter-result-data,
        .filter-title {
            @extend %d-none;
        }
    }
    .waf-head {
        margin-bottom: 5.6rem;
    }
    .title {
        @extend %d-none;
    }
    .head-tab {
        // @include position(7rem,var(--full-space),null,var(--full-space));
        @extend %w-100;
        @extend %text-center;
        a {
            @extend %w-100;
            @extend %flex-c-c;
            @extend %c-blue-10;
            @extend .btn-fill;
        }
    }
    .modal-window {
        top: 6rem;
        flex-direction: column;
        @extend %d-none;
        @extend %c-blue-bg-8;
        .modal-head {
            z-index: var(--zindexmodalclose);
            @include position(0, null, null, 0);
            .btn-text {
                &::after {
                    display: inline-block;
                }
            }
        }
        .modal-body {
            align-items: flex-start;
            max-height: unset;
            height: var(--window-inner-height);
        }
        .article {
            &-list {
                gap: 0;
                padding-top: 7rem;
                @extend %h-100;
                @include flex-config(flex, row, null, center);
            }
            &-item {
                flex-shrink: 0;
                margin-bottom: 0;
                @extend %flex-c-c;
                &:hover {
                    img {
                        transform: unset;
                    }
                }
            }
            &-wrap {
                @extend %w-100;
            }
        }
        .preview-swiper {
            height: calc(100% - 13rem);
            @extend %w-100;
        }
        .btn-download {
            // @extend %d-none;
            @extend %flex-c-c;
            @include position(var(--half-space), var(--half-space));
            &::before {
                @extend %c-white-10;
                @include icon('\e809', 20);
            }
            &:hover {
                &::before {
                    @extend %c-orange-10;
                }
            }
            .btn-text {
                font-size: 0;
            }
        }
        .swiper-button {
            &-prev,
            &-next {
                cursor: pointer;
                width: 3rem;
                height: 3rem;
                top: calc(50% + 7rem);
                transform: translateY(-50%);
                @extend %text-center;
                @extend %flex-c-c;
                @extend %circle-radius;
                &::after {
                    content: "";
                    @extend %font-12;
                    @extend %c-white-10;
                }
                &.swiper-button-disabled {
                    pointer-events: none;
                    @extend %c-white-bg-4;
                }
                &:hover {
                    &::after {
                        @extend %c-orange-10;
                    }
                }
            }
            &-prev {
                left: 0;
                &::after {
                    @include icon('\e800', 20);
                }
            }
            &-next {
                right: 0;
                left: auto;
                &::after {
                    @include icon('\e801', 20);
                }
            }
        }
        .swiper-scrollbar,
        .swiper-pagination {
            @extend %d-none;
        }
        .reaction-section {
            gap: var(--half-space);
            @include position(-5.4rem, var(--full-space), null, null);
            @extend %flex-c-c;
            .share-icon {
                @extend %flex-c-c;
            }
            .icon-b-share {
                &::before {
                    font-size: 2rem;
                }
            }
        }
        .btn-download,
        .btn-zoom,
        .social-share {
            width: 3rem;
            height: 3rem;
            position: unset;
        }
        .btn-zoom {
            @extend %flex-c-c;
            &::after {
                @include icon('\e829', 24);
                @extend %c-white-10;
            }
            .btn-text {
                font-size: 0;
            }
        }
        .btn-close {
            &:hover {
                .btn-text {
                    @extend %c-orange-10;
                    &::after {
                        @extend %c-orange-10;
                    }
                }
            }
        }
    }
    &.tab-item-photo {
        background: transparent;
    }
}
@media (min-width: $tablet-min-width) {
    .photo-listing {
        .waf-listing {
            padding: 5rem 0 4rem;
            .waf-head {
                margin-bottom: 6.6rem;
            }
            .head-wrap {
                justify-content: flex-end;
            }
            .head-tab {
                position: unset;
            }
            .waf-body {
                position: relative;
            }
        }
        .article {
            &-list {
                @include grid(repeat(3, 1fr), var(--one-n-half-space));
            }
            &-item {
                margin: 0;
                &.img-4by3 {
                    grid-row-end: span 2;
                }
                &.img-2by3 {
                    grid-row-end: span 3;
                }
                &.img-9by16 {
                    grid-row-end: span 4;
                }
            }
            &-thumbnail {
                .img-box {
                    height: 100%;
                }
            }
            &-wrap,
            &-thumbnail {
                height: 100%;
            }
        }
        .head-tab {
            width: 20rem;
            @include position(0, var(--container-white-space), null, auto);
        }
        .filter-section {
            width: 33rem;
            margin-bottom: 0;
            top: -10.6rem;
            left: 0;
            .filter-search {
                position: static;
            }
            input {
                font-size: 1.4rem;
            }
            .search-wrapper {
                width: 100%;
            }
        }
        .modal-window {
            top: 0;
            height: var(--window-inner-height);
            padding-inline: calc(var(--container-white-space)*1);
            padding-bottom: calc(5*var(--full-space));
            .modal-head {
                position: unset;
            }
            .preview-swiper {
                width: 100%;
                height: 100%;
                overflow: visible;
                @include flex-config(flex);
            }
            .modal-body {
                align-items: center;
                max-height: unset;
                height: calc(100% - 4rem);
            }
            img {
                object-fit: cover;
            }
            .article-list {
                padding-top: 0;
            }
            .swiper {
                &::after {
                    content: unset;
                }
                &-button {
                    @include position-combo('y-center');
                    &-prev {
                        left: calc(var(--two-space)*-1);
                        &::after {
                            font-size: 2.4rem;
                        }
                    }
                    &-next {
                        right: calc(var(--two-space)*-1);
                        &::after {
                            font-size: 2.4rem;
                        }
                    }
                }
                &-slide {
                    opacity: 0;
                    &-active {
                        opacity: 1;
                    }
                }
            }
            .reaction-section {
                width: 12rem;
                @include position(unset, 0, -3.5rem, null);
                @include flex-config(flex, null, flex-end, center);
            }
            .btn-download {
                &::before {
                    font-size: 2.4rem;
                }
            }
            .btn-close {
                .btn-text {
                    padding-left: var(--half-space);
                }
            }
        }
    }
}